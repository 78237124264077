import React, { Component } from "react";
import Api from "../../Services/Api";
import { LoginSelectors } from "../../Redux/LoginRedux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemText,
  ListItem,
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  withStyles,
  StepConnector,
  makeStyles,
  withTheme,
  Button,
} from "@material-ui/core";
import styles from "./style.module.scss";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CarIcon from "@material-ui/icons/DriveEta";
import ShoppingCartIcon from "@material-ui/icons/ShoppingBasket";
import FoodIcon from "@material-ui/icons/LocalDining";
import { toast } from "react-toastify";
import NavBar from "../../components/Navbar/Navbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OrderActions, { OrderSelectors } from "../../Redux/OrderRedux";
import moment from "moment";
import background_1 from "../../assets/images/backimage_1.jpeg";
import booking_background from "../../assets/images/app_booking3.png";
import common_background from "../../assets/images/common_bg.png";
import createUrlToScreen from "../../Services/Utils";
import Loading from "../../components/Loading/Loading";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import PropTypes from "prop-types";
// import { OrderSelectors } from "../../Redux/OrderRedux";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#fc6167",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#fc6167",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#fc6167",
  },
  completed: {
    backgroundColor: "#fc6167",
  },
});

function PickupColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Check />,
    2: <ShoppingCartIcon />,
    3: <FoodIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
function DeliveryColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <InfoIcon />,
    2: <CarIcon />,
    3: <FoodIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

PickupColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

DeliveryColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    let storedData = JSON.parse(localStorage.getItem("restaurantInfoData"));

    if (!storedData) {
      storedData = false;
    }

    console.log("OrderHistory props ", props);
    this.state = {
      orderHistory: [],
      loading: true,
      error: false,
      intervalId: null,
      recentOrder: null,
      recentIndex: null,
      restaurant_info_data: storedData,
    };
  }

  shouldComponentUpdate() {
    // if (!this.props.isUserLoggedIn) {
    //   this.props.history.push(createUrlToScreen("", this.props.match.url));
    // }
    return true;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getOrderHistory();
    // this.getOrderHistoryInterval();
    if (!this.props.isUserLoggedIn) {
      this.setState({ loading: false });
    }
  }
  componentDidUpdate(prevProps) {
    // Check if user just logged in and if the current page is the orderHistory page
    if (!prevProps.isUserLoggedIn && this.props.isUserLoggedIn) {
      this.getOrderHistory();
    }
  }

  componentWillUnmount() {
    this.removeInterval();
  }

  removeInterval = () => {};

  getOrderHistoryInterval = () => {
    if (!this.state.intervalID) {
      this.getOrderStatus();
      const intervalId = setInterval(this.getOrderStatus, 45000);
      this.setState({
        intervalId: intervalId,
        intervalNumber: this.state.intervalNumber + 1,
      });
    }
  };

  getOrderStatus = () => {
    if (this.state.orderHistory?.length) {
      const apiService = Api.create();
      let allOrders = this.state.orderHistory;
      allOrders.forEach((order, index) => {
        if (order.order_status != 5) {
          apiService
            .getOrderStatus(
              order.business_id,
              order.location_id,
              order.order_number
            )
            .then((response) => {
              allOrders[index].order_message = response?.data?.order_message;
              if (
                allOrders[index].order_status != response?.data?.order_status
              ) {
                allOrders[index].order_status = response?.data?.order_status;
              }
            });
        }
        if (index == allOrders.length - 1) {
          this.setState({
            orderHistoryJson: allOrders,
          });
        }
      });
    }
  };

  renderTime = (timeStamp) => {
    var date = moment.utc(timeStamp);
    return date.local().format("DD MMM LT");
  };

  getOrderHistory = () => {
    if (this.props.isUserLoggedIn && this.props.userInfo) {
      const apiService = Api.create();
      if (this.props.phoneOrder) {
        var requestObj = {
          business_id: this.props.restaurantInfo?.info?.business_id,
          location_id: this.props.restaurantInfo?.info?.location_id,
        };
        apiService.getOrderHistory(true, requestObj).then((response) => {
          if (response.ok && response.status == 200) {
            this.setState({
              orderHistory: response.data.orders_list.reverse(),
              loading: false,
              error: false,
            });
          } else if (this.state.orderHistory == {}) {
            this.setState({ loading: false, error: false });
          }
        });
      } else {
        var requestObj = {
          customer_id: this.props.userInfo.customer_id,
          token: this.props.userInfo.token,
        };
        apiService.getOrderHistory(false, requestObj).then((response) => {
          if (response.ok && response.status == 200) {
            this.setState({
              orderHistory: response.data.orders_list.reverse(),
              loading: false,
              error: false,
            });
          } else if (this.state.orderHistory == {}) {
            this.setState({ loading: false, error: false });
          }
        });
      }
    } else {
      // inside else
    }
  };

  getSteps = (order) => {
    if (order.is_appointment) {
      if (order.order_type == 2) {
        if (order.order_status === 1) {
          return ["Not Confirmed", "Completed"];
        } else {
          return ["Confirmed", "Completed"];
        }
      }
    } else {
      if (order.order_type == 1) {
        if (order.order_status == 1) {
          return ["Not Confirmed", "On Delivery", "Completed"];
        } else {
          return ["Confirmed", "On Delivery", "Completed"];
        }
      } else if (order.order_type == 2) {
        if (order.order_status == 1) {
          return ["Not Confirmed", "Ready for Pickup", "Completed"];
        } else {
          return ["Confirmed", "Ready for Pickup", "Completed"];
        }
      } else if (order.order_type == 3) {
        if (order.order_status == 1) {
          return ["Not Confirmed", "Ready for Serving", "Completed"];
        } else {
          return ["Confirmed", "Ready for Serving", "Completed"];
        }
      }
    }
  };

  getActiveStep = (order) => {
    if (order.order_type == 1) {
      if (+order.order_status == 4) {
        return 1;
      } else if (+order.order_status - 2 >= 0) {
        return +order.order_status - 2;
      } else {
        return 0;
      }
    } else {
      if (+order.order_status - 2 >= 0) {
        return +order.order_status - 2;
      } else {
        return 0;
      }
    }
  };

  getStepIcon = (step, index, order) => {
    const activeStep = this.getActiveStep(order);
    let isActive = false;
    if (index == activeStep) isActive = true;
    switch (step) {
      case "Completed":
        return (
          <div>
            <FoodIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      case "Not Confirmed":
        return (
          <div>
            <InfoIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      case "On Delivery":
        return (
          <div>
            <CarIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      case "Confirmed":
        return (
          <div>
            <InfoIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      case "Ready for Pickup":
        return (
          <div>
            <ShoppingCartIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      case "Ready for Serving":
        return (
          <div>
            <CarIcon
              style={isActive ? { color: "#fc6167" } : { color: "#e2e2e2" }}
            />
          </div>
        );
      default:
        return null;
    }
  };
  setThemeColor = () => {
    if (this.props.theme.palette.secondary.main !== "#fc6167") {
      this.props.onThemeColorChange("#fc6167");
    }
  };

  orderAgain = (order) => {
    if (order.order_type == 3) {
      this.props.history.push(
        "/dine-in/" +
          order.business_name_url +
          "/" +
          order.location_name_url +
          "/" +
          order.room_name +
          "/" +
          order.table_number
      );
    } else {
      this.props.history.push(
        "/web/" + order.business_name_url + "/" + order.location_name_url
      );
    }
  };
  bookAgain = (order) => {
    this.props.history.push(
      "/web/" + order.business_name_url + "/" + order.location_name_url
    );
  };
  // bookAgain = (order) => {
  //   this.props.history.push(
  //     "/web/" + order.business_name_url + "/" + order.location_name_url
  //   );
  // };

  showAppointmentScreen = (order) => {
    this.props.history.push(
      `/web/${order.business_name_url}/${order.location_name_url}/showAppointment/${order?.business_id}/${order?.appointment_details?.id}`
    );
  };

  handleViewAppointmentClick = (order) => {
    this.props.updateSelectedOrder(order);
    this.showAppointmentScreen(order);
  };

  showOpenDeliveryurl = (value) => {
    window.open(value);
  };

  render() {
    console.log("restaurant_info_data ", this.state.restaurant_info_data);

    this.setThemeColor();
    const { orderHistory } = this.state;
    console.log("orderHistory :", orderHistory);
    // Check if any order contains appointment_details
    // const hasAnyAppointmentDetails = orderHistory?.some(
    //   (order) => order.appointment_details
    // );
    // console.log("hasAnyAppointmentDetails ", hasAnyAppointmentDetails);
    const hasAppointmentDetails = orderHistory[0]?.appointment_details
      ? true
      : false;

    console.log("hasAppointmentDetails :", hasAppointmentDetails);

    // Determine the background image
    const backgroundImage = common_background;
    // const backgroundImage = !this.props.isUserLoggedIn
    //   ? common_background
    //   : hasAppointmentDetails || this.state.restaurant_info_data.is_service
    //   ? booking_background
    //   : background_1;

    return (
      <>
        {this.state.loading && (
          <Box style={{ minHeight: "calc(100vh - 40px)" }}>
            <Grid
              container
              justify="space-around"
              style={{ height: "calc(100vh - 40px)" }}
              alignItems="center"
              alignContent="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                style={{ maxHeight: "20vh", maxWidth: "20vh" }}
              >
                <Loading title="Loading Past Orders" />
              </Grid>
            </Grid>
          </Box>
        )}

        {(!this.state.loading || !this.props.isUserLoggedIn) && (
          <Box
            className={styles.backgroudImage}
            style={{
              minHeight: "calc(100vh - 40px)",
              backgroundImage: "url(" + backgroundImage + ")",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              width: "100%",
              overflow: "hidden",
              height: "calc(100vh - 40px)",
            }}
          >
            <NavBar ootg={true} />
            <Container
              className={styles.mainBox}
              fluid
              maxWidth={false}
              disableGutters={true}
            >
              {!this.props.isUserLoggedIn && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justify="center"
                    alignItems="center"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#fff",
                      padding: 20,
                      margin: 50,
                    }}
                  >
                    <span>Please login to access this page</span>
                  </Grid>
                </Grid>
              )}
              {this.props.isUserLoggedIn &&
                this.state.orderHistory?.map((order) => {
                  if (order.is_appointment) {
                    let init_time = order?.appointment_details?.start_time;
                    let formatted_time = init_time.split(" ")[1];
                    let original_time = formatted_time.split(":", 2).join(":");
                    return (
                      <Grid
                        container
                        spacing={2}
                        justify="center"
                        classes={{ root: styles.mainContainer }}
                      >
                        <Grid item xs={12} sm={10} md={8} lg={4}>
                          <Card>
                            <CardContent classes={{ root: styles.cardStyle }}>
                              <CardHeader
                                classes={{ root: styles.checkoutHeader }}
                                title={
                                  <Grid container>
                                    <Grid item xs={6}>
                                      {order?.business_name}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Grid container justify="flex-end">
                                        <Button
                                          onClick={() => this.bookAgain(order)}
                                          color="secondary"
                                          variant="outlined"
                                        >
                                          Book Again
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                }
                                subheader={
                                  <Grid container>
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        {order?.business_intersection}
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        Order: {order?.order_number}
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        {" "}
                                        Placed at:
                                        {order?.receipt?.order_placed
                                          ? this.renderTime(
                                              order?.receipt?.order_placed
                                            )
                                          : ""}
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        Appointment At: {original_time}
                                      </div>
                                    </Grid>
                                  </Grid>
                                }
                              />
                              {order.order_message &&
                                order.order_type !== 3 && (
                                  <Grid item xs={12}>
                                    <div className={styles.centerMessage}>
                                      {order.order_message}
                                    </div>
                                  </Grid>
                                )}
                              {!(
                                order?.order_status == "5" ||
                                order?.order_status == "6"
                              ) && (
                                <Stepper
                                  activeStep={this.getActiveStep(order)}
                                  alternativeLabel
                                  connector={<ColorlibConnector />}
                                >
                                  {this.getSteps(order).map((step, index) => {
                                    return (
                                      <Step key={step}>
                                        <StepLabel
                                          StepIconProps={{
                                            classes: {
                                              completed: styles.completedIcon,
                                            },
                                          }}
                                          StepIconComponent={
                                            PickupColorlibStepIcon
                                          }
                                        >
                                          {step}
                                        </StepLabel>
                                      </Step>
                                    );
                                  })}
                                </Stepper>
                              )}
                              <Divider />
                              <Grid
                                container
                                spacing={3}
                                style={{ padding: "10px" }}
                              >
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "end",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>
                                      <Button
                                        onClick={() =>
                                          this.handleViewAppointmentClick(order)
                                        }
                                        color="secondary"
                                        variant="outlined"
                                      >
                                        View Appointment
                                      </Button>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              <Divider />
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div>Appointment Receipt</div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <List style={{ width: "100%" }}>
                                    {console.log("ord", order)}
                                    {order.receipt.item_list.map((product) => (
                                      <ListItem alignItems="flex-start">
                                        <ListItemText
                                          primary={product.description}
                                          secondary={
                                            product.modifiers.length ? (
                                              <Grid item xs={12} sm={12}>
                                                <List>
                                                  {product.modifiers.map(
                                                    (modifier) => (
                                                      <ListItem
                                                        classes={{
                                                          root: styles.modifierItemStyle,
                                                        }}
                                                        key={
                                                          "modi" +
                                                          modifier.modifier_id
                                                        }
                                                      >
                                                        {modifier}
                                                      </ListItem>
                                                    )
                                                  )}
                                                </List>
                                              </Grid>
                                            ) : null
                                          }
                                        />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={product.cost}
                                        />
                                      </ListItem>
                                    ))}
                                    <Divider />
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Subtotal" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={
                                          order.receipt.food_beverage_subtotal
                                        }
                                      />
                                    </ListItem>
                                    {order.order_type == 1 && (
                                      <ListItem
                                        alignItems="flex-start"
                                        classes={{
                                          root: styles.receiptLineItem,
                                        }}
                                      >
                                        <ListItemText primary="Delivery Fees" />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={order.receipt.delivery_fee}
                                        />
                                      </ListItem>
                                    )}
                                    {order.coupon_amount && (
                                      <ListItem
                                        alignItems="flex-start"
                                        classes={{
                                          root: styles.receiptLineItem,
                                        }}
                                      >
                                        <ListItemText primary="Discount" />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={`- ${order.receipt.coupon_amount}`}
                                        />
                                      </ListItem>
                                    )}
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Tip" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.tip_cost}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Tax" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.tax}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Total" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.total}
                                      />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText
                                        primary={order.receipt.paid_text}
                                      />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.paid_value}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Date" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.date}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Message" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.message}
                                      />
                                    </ListItem>
                                    <Divider />
                                    {order?.tracking_url && (
                                      <>
                                        <ListItem
                                          alignItems="flex-end"
                                          classes={{
                                            root: styles.receiptLineItem,
                                          }}
                                        >
                                          <ListItemText primary="Tracking URL" />
                                        </ListItem>
                                        <Button
                                          onClick={() =>
                                            this.showOpenDeliveryurl(
                                              order.tracking_url
                                            )
                                          }
                                          color="secondary"
                                          size="small"
                                          style={{ textTransform: "lowercase" }}
                                        >
                                          {order.tracking_url}
                                        </Button>
                                      </>
                                    )}
                                  </List>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        container
                        spacing={2}
                        justify="center"
                        classes={{ root: styles.mainContainer }}
                      >
                        <Grid item xs={12} sm={10} md={8} lg={4}>
                          <Card>
                            <CardContent classes={{ root: styles.cardStyle }}>
                              <CardHeader
                                classes={{ root: styles.checkoutHeader }}
                                title={
                                  <Grid container>
                                    <Grid item xs={6}>
                                      {order?.business_name}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Grid container justify="flex-end">
                                        <Button
                                          onClick={() => this.orderAgain(order)}
                                          color="secondary"
                                          variant="outlined"
                                        >
                                          Order Again
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                }
                                subheader={
                                  <Grid container>
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        {order?.business_intersection}
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      {order.order_type == 1 &&
                                        order.integration_type ==
                                          "doordash" && (
                                          <div
                                            class="font16"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            Delivery - Door Dash
                                          </div>
                                        )}
                                      {order.order_type == 1 &&
                                        order.integration_type === null && (
                                          <div
                                            class="font16"
                                            style={{
                                              width: "100%",
                                            }}
                                          >
                                            Delivery
                                          </div>
                                        )}
                                      {order.order_type == 2 && (
                                        <div
                                          class="font16"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          Pickup
                                        </div>
                                      )}
                                      {order.order_type == 3 && (
                                        <div
                                          class="font16"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          Dine In
                                        </div>
                                      )}
                                    </Grid>
                                    {order.order_type !== 3 && (
                                      <Grid item xs={12} sm={12}>
                                        <div
                                          class="font16"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          Requested Time:
                                          {order?.receipt
                                            ?.order_requested_time !== "ASAP"
                                            ? this.renderTime(
                                                order?.receipt
                                                  ?.order_requested_time
                                              )
                                            : "ASAP"}
                                        </div>
                                      </Grid>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        Order: {order?.order_number}
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <div class="subhead">
                                        {" "}
                                        Placed at:
                                        {order?.receipt?.order_placed
                                          ? this.renderTime(
                                              order?.receipt?.order_placed
                                            )
                                          : ""}
                                      </div>
                                    </Grid>
                                  </Grid>
                                }
                              />
                              {order.order_message &&
                                order.order_type !== 3 && (
                                  <Grid item xs={12}>
                                    <div className={styles.centerMessage}>
                                      {order.order_message}
                                    </div>
                                  </Grid>
                                )}
                              {!(
                                order?.order_status == "5" ||
                                order?.order_status == "6"
                              ) && (
                                <Stepper
                                  activeStep={this.getActiveStep(order)}
                                  alternativeLabel
                                  connector={<ColorlibConnector />}
                                >
                                  {this.getSteps(order).map((step, index) => {
                                    if (order?.order_type == 1) {
                                      return (
                                        <Step key={step}>
                                          <StepLabel
                                            StepIconProps={{
                                              classes: {
                                                completed: styles.completedIcon,
                                              },
                                            }}
                                            StepIconComponent={
                                              DeliveryColorlibStepIcon
                                            }
                                          >
                                            {step}
                                          </StepLabel>
                                        </Step>
                                      );
                                    } else {
                                      return (
                                        <Step key={step}>
                                          <StepLabel
                                            StepIconProps={{
                                              classes: {
                                                completed: styles.completedIcon,
                                              },
                                            }}
                                            StepIconComponent={
                                              PickupColorlibStepIcon
                                            }
                                          >
                                            {step}
                                          </StepLabel>
                                        </Step>
                                      );
                                    }
                                  })}
                                </Stepper>
                              )}
                              <Divider />

                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <div>Order Receipt</div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <List style={{ width: "100%" }}>
                                    {console.log("ord", order)}
                                    {order.receipt.item_list.map((product) => (
                                      <ListItem alignItems="flex-start">
                                        <ListItemText
                                          primary={product.description}
                                          secondary={
                                            product.modifiers.length ? (
                                              <Grid item xs={12} sm={12}>
                                                <List>
                                                  {product.modifiers.map(
                                                    (modifier) => (
                                                      <ListItem
                                                        classes={{
                                                          root: styles.modifierItemStyle,
                                                        }}
                                                        key={
                                                          "modi" +
                                                          modifier.modifier_id
                                                        }
                                                      >
                                                        {modifier}
                                                      </ListItem>
                                                    )
                                                  )}
                                                </List>
                                              </Grid>
                                            ) : null
                                          }
                                        />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={product.cost}
                                        />
                                      </ListItem>
                                    ))}
                                    <Divider />
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Food & Beverages Subtotal" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={
                                          order.receipt.food_beverage_subtotal
                                        }
                                      />
                                    </ListItem>
                                    {order.order_type == 1 && (
                                      <ListItem
                                        alignItems="flex-start"
                                        classes={{
                                          root: styles.receiptLineItem,
                                        }}
                                      >
                                        <ListItemText primary="Delivery Fees" />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={order.receipt.delivery_fee}
                                        />
                                      </ListItem>
                                    )}
                                    {order.coupon_amount && (
                                      <ListItem
                                        alignItems="flex-start"
                                        classes={{
                                          root: styles.receiptLineItem,
                                        }}
                                      >
                                        <ListItemText primary="Discount" />
                                        <ListItemText
                                          classes={{ root: styles.amountStyle }}
                                          edge="end"
                                          primary={`- ${order.receipt.coupon_amount}`}
                                        />
                                      </ListItem>
                                    )}
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Tip" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.tip_cost}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Tax" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.tax}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Total" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.total}
                                      />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText
                                        primary={order.receipt.paid_text}
                                      />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.paid_value}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Date" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.date}
                                      />
                                    </ListItem>
                                    <ListItem
                                      alignItems="flex-start"
                                      classes={{ root: styles.receiptLineItem }}
                                    >
                                      <ListItemText primary="Message" />
                                      <ListItemText
                                        classes={{ root: styles.amountStyle }}
                                        edge="end"
                                        primary={order.receipt.message}
                                      />
                                    </ListItem>
                                    <Divider />
                                    {order?.tracking_url && (
                                      <>
                                        <ListItem
                                          alignItems="flex-end"
                                          classes={{
                                            root: styles.receiptLineItem,
                                          }}
                                        >
                                          <ListItemText primary="Tracking URL" />
                                        </ListItem>
                                        <Button
                                          onClick={() =>
                                            this.showOpenDeliveryurl(
                                              order.tracking_url
                                            )
                                          }
                                          color="secondary"
                                          size="small"
                                          style={{ textTransform: "lowercase" }}
                                        >
                                          {order.tracking_url}
                                        </Button>
                                      </>
                                    )}
                                  </List>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    );
                  }
                })}
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoggedIn: LoginSelectors.isUserloggedIn(state),
    userInfo: LoginSelectors.getUserInfo(state),
    restaurantInfo: OrderSelectors.getRestaurantInfo(state),
  };
};
// Map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  updateSelectedOrder: (order) =>
    dispatch(OrderActions.updateSelectedOrder(order)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(OrderHistory))
);
